import React, {useEffect} from 'react';
import axiosInstance from "./axiosConfig";
import {
    Box,
    TextField,
} from '@mui/material';
import '../components/modal.css'
import moment from "moment-timezone";
import { styled } from '@mui/system';
import { successMessage } from '../utilComponents/ToastMassage';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchBikeModelDetails = async (model_name) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/bike-model/${model_name}/`);
        // Assuming the response contains the details of the bike model
        return response.data;
    } catch (error) {
        console.error("Error fetching bike model details:", error);
        return null;
    }
};

// # this api is not using rn 
export const fetchCashierCustomerList = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/cashier/customers-bikes/`);

        return response.data;

    } catch (error) {
        console.error("Error fetching bike model details:", error);
        return null;
    }
};

export const fetchBikeModels = async (bikeModel) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/bike-model/${bikeModel}/`);
        // Assuming the response contains the details of the bike model
        return response.data;
    } catch (error) {
        console.error("Error fetching bike model details:", error);
        return null;
    }
};

export function formatNumberIndian(num) {
    if (num === null || num === undefined) return num;

    let str = num.toString();
    let afterPoint = '';
    if (str.indexOf('.') > 0) {
        afterPoint = str.substring(str.indexOf('.'), str.length);
    }
    str = Math.floor(num);
    str = str.toString();
    let lastThree = str.substring(str.length - 3);
    const otherNumbers = str.substring(0, str.length - 3);
    if (otherNumbers !== '') {
        lastThree = ',' + lastThree;
    }
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
}

export const fetchSalesDetailsByCustomerID = async (customerId) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/sales/by-customer/${customerId}/`);
        // console.log(response)

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};
export const fetchCustomerAccessories = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/accessory-stage-list/`);

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const useDisableNumberInputScroll = () => {
    // Use the useEffect hook to manage side effects
    useEffect(() => {
        // Define a function to prevent the default scroll behavior
        const handleWheel = (e) => {
            e.preventDefault();
        };

        // Find all number input elements in the document
        const numberInputs = document.querySelectorAll('input[type="number"]');

        // Attach the handleWheel function as an event listener to each number input
        numberInputs.forEach((input) => {
            input.addEventListener('wheel', handleWheel, { passive: false });
        });

        // Clean up by removing the event listeners when the component unmounts
        return () => {
            numberInputs.forEach((input) => {
                input.removeEventListener('wheel', handleWheel);
            });
        };
    }, []); // The empty dependency array ensures that this effect runs once, like componentDidMount
};


export const fetchAccountsDetails = async (id, type) => {

    try {
        let response;
        if (type === 'customer') {
            response = await axiosInstance.get(`${API_URL}/api/accounts/by-customer/${id}/`);
        } else if (type === 'bike') {
            response = await axiosInstance.get(`${API_URL}/api/accounts/update_by_bike/${id}/`);
        } else {
            throw new Error('Invalid type specified.');
        }
        return response.data;
    } catch (error) {
        console.error("Error fetching accounts details:", error);
        return null;
    }
};

// # this api is not using anywhere 
export  const fetchBikesByBranch = async () => {
    try {
        const response1 = await axiosInstance.get(`${API_URL}/api/bikes/branch/`);
        const response2 = await axiosInstance.get(`${API_URL}/api/bikes/one/branch/`);

        return [...response1.data, ...response2.data];
    } catch (error) {
        console.error("Error fetching bikes:", error);
        return [];
    }
};


    export const fetchBikesBYStage = async ({
    stage = '',
    pagination = {},
    columnFilters = {},
    globalFilter = '',
    sorting = []
  }) => {

    const url = new URL(`${API_URL}/api/filter-bikes/`);
  
    url.searchParams.set('stage', stage);
    url.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
    url.searchParams.set('size', `${pagination.pageSize}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters));
    url.searchParams.set('globalFilter', globalFilter);
    url.searchParams.set('sorting', JSON.stringify(sorting));
  
    try {
      const response = await axiosInstance.get(url.href);
      return response.data;
    } catch (error) {
      console.error("Error fetching bikes:", error.message);
      throw error; // rethrow the error so that the caller can handle it
    }
    };

    export const fetchBikesRTO = async ({
        stage = '',
        pagination = {},
        columnFilters = {},
        globalFilter = '',
        sorting = []
      }) => {
    
        const url = new URL(`${API_URL}/api/filter-bikes-rto/`);
      
        url.searchParams.set('stage', stage);
        url.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set('size', `${pagination.pageSize}`);
        url.searchParams.set('filters', JSON.stringify(columnFilters));
        url.searchParams.set('globalFilter', globalFilter);
        url.searchParams.set('sorting', JSON.stringify(sorting));
      
        try {
          const response = await axiosInstance.get(url.href);
          return response.data;
        } catch (error) {
          console.error("Error fetching bikes:", error.message);
          throw error; // rethrow the error so that the caller can handle it
        }
        };

    export const fetchBikesForHSRP = async ({
        pagination = {},
        columnFilters = {},
        globalFilter = '',
        sorting = []
      }) => {
    
        const url = new URL(`${API_URL}/api/hsrp-filter-bikes/`);
      
        url.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set('size', `${pagination.pageSize}`);
        url.searchParams.set('filters', JSON.stringify(columnFilters));
        url.searchParams.set('globalFilter', globalFilter);
        url.searchParams.set('sorting', JSON.stringify(sorting));
      
        try {
          const response = await axiosInstance.get(url.href);
          return response.data;
        } catch (error) {
          console.error("Error fetching bikes:", error.message);
          throw error; // rethrow the error so that the caller can handle it
        }
    };

    export const fetchAccessoriesCustomerList = async ({
        pagination = {},
        columnFilters = {},
        globalFilter = '',
        sorting = []
      }) => {
    
        const url = new URL(`${API_URL}/api/filter-accessories-customer/`);
      
        url.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set('size', `${pagination.pageSize}`);
        url.searchParams.set('filters', JSON.stringify(columnFilters));
        url.searchParams.set('globalFilter', globalFilter);
        url.searchParams.set('sorting', JSON.stringify(sorting));
      
        try {
          const response = await axiosInstance.get(url.href);
          return response.data;
        } catch (error) {
          console.error("Error fetching bikes:", error.message);
          throw error; // rethrow the error so that the caller can handle it
        }
    };

    export const fetchCustomerBYStage = async ({
        stage = '',
        pagination = {},
        columnFilters = {},
        globalFilter = '',
        sorting = []
        }) => {

        const url = new URL(`${API_URL}/api/filter-customer/`);

        url.searchParams.set('stage', stage);
        url.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set('size', `${pagination.pageSize}`);
        url.searchParams.set('filters', JSON.stringify(columnFilters));
        url.searchParams.set('globalFilter', globalFilter);
        url.searchParams.set('sorting', JSON.stringify(sorting));

        try {
            const response = await axiosInstance.get(url.href);
            return response.data;
        } catch (error) {
            console.error("Error fetching bikes:", error.message);
            throw error; // rethrow the error so that the caller can handle it
        }
    };
    
    export const fetchSalesPersonCustomers = async ({
        pagination = {},
        columnFilters = {},
        globalFilter = '',
        sorting = []
        }) => {

        const url = new URL(`${API_URL}/api/sales-person-customers/`);

        url.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set('size', `${pagination.pageSize}`);
        url.searchParams.set('filters', JSON.stringify(columnFilters));
        url.searchParams.set('globalFilter', globalFilter);
        url.searchParams.set('sorting', JSON.stringify(sorting));

        try {
            const response = await axiosInstance.get(url.href);
            return response.data;
        } catch (error) {
            console.error("Error fetching bikes:", error.message);
            throw error; // rethrow the error so that the caller can handle it
        }
    };

    export const fetchInvoiceBike = async ({
        pagination = {},
        columnFilters = {},
        globalFilter = '',
        sorting = []
        }) => {

        const url = new URL(`${API_URL}/api/invoice-filter-bikes/`);

        url.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set('size', `${pagination.pageSize}`);
        url.searchParams.set('filters', JSON.stringify(columnFilters));
        url.searchParams.set('globalFilter', globalFilter);
        url.searchParams.set('sorting', JSON.stringify(sorting));

        try {
            const response = await axiosInstance.get(url.href);
            return response.data;
        } catch (error) {
            console.error("Error fetching bikes:", error.message);
            throw error; // rethrow the error so that the caller can handle it
        }
    };

    export const fetchListForCashier = async ({
        pagination = {},
        columnFilters = {},
        globalFilter = '',
        sorting = []
    }) => {

        const url = new URL(`${API_URL}/api/filter-cashier-list/`);

        url.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set('size', `${pagination.pageSize}`);
        url.searchParams.set('filters', JSON.stringify(columnFilters));
        url.searchParams.set('globalFilter', globalFilter);
        url.searchParams.set('sorting', JSON.stringify(sorting));
    
        try {
        const response = await axiosInstance.get(url.href);
        const data = response.data
        //   console.log(data.results.results , 'data.results.results.data  data.results.results.data ')
        return {
            count: data.count,
            start: data.start,
            size: data.size,
            results: data.results.results // Flatten the data structure if necessary
        };
        } catch (error) {
        console.error("Error fetching bikes:", error.message);
        throw error; // rethrow the error so that the caller can handle it
        }
    };

    export const fetchSearchListForCashier = async ({ pagination, searchKey, searchValue }) => {
        try {
            const response = await axiosInstance.get('/api/cashier/search/', {
                params: {
                    [searchKey]: searchValue, // Pass the search key and value dynamically
                },
            });
    
            // Assuming response.data contains the results and the count (for pagination)
            return {
                count: response.data.length || 0,  // Adjust if API gives a different response structure
                results: response.data,
            };
        } catch (error) {
            console.error('Error fetching cashier list:', error);
            throw error;
        }
    };

    export const fetchCashierApprovalList = async ({
        pagination = {},
        columnFilters = {},
        globalFilter = '',
        sorting = []
    }) => {

        const url = new URL(`${API_URL}/api/cashier-approval-list/`);
        url.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set('size', `${pagination.pageSize}`);
        url.searchParams.set('filters', JSON.stringify(columnFilters));
        url.searchParams.set('globalFilter', globalFilter);
        url.searchParams.set('sorting', JSON.stringify(sorting));
    
        try {
        const response = await axiosInstance.get(url.href);
        const data = response.data
        //   console.log(data.results.results , 'data.results.results.data  data.results.results.data ')
        return {
            count: data.count,
            start: data.start,
            size: data.size,
            results: data.results // Flatten the data structure if necessary
        };
        } catch (error) {
        console.error("Error fetching bikes:", error.message);
        throw error; // rethrow the error so that the caller can handle it
        }
    };
    
    export const fetchDetailsApprovalList = async ({
        pagination = {},
        columnFilters = {},
        globalFilter = '',
        sorting = []
    }) => {

        const url = new URL(`${API_URL}/api/discount-approval-list/`);
        url.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set('size', `${pagination.pageSize}`);
        url.searchParams.set('filters', JSON.stringify(columnFilters));
        url.searchParams.set('globalFilter', globalFilter);
        url.searchParams.set('sorting', JSON.stringify(sorting));
    
        try {
        const response = await axiosInstance.get(url.href);
        const data = response.data
        return {
            count: data.count,
            start: data.start,
            size: data.size,
            results: data.results // Flatten the data structure if necessary
        };
        } catch (error) {
        console.error("Error fetching bikes:", error.message);
        throw error; // rethrow the error so that the caller can handle it
        }
    };

    export const fetchReceivedAmountCashier = async (customerId) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/api/customer/${customerId}/total-paid/`);

            return response.data;
        } catch (error) {
            console.error("Error fetching sales details:", error);
            return null;
        }
    };

    // fetch a bikes insurance details by its ID
    export const fetchInsuranceDetails = async (bikeId) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/api/insurance/by_bike/${bikeId}/`);
            return response.data;
        } catch (error) {
            console.error("Error fetching sales details:", error);
            return null;
        }
    };

    export const fetchCustomersInAccounts = async () => {
        try {
            const response = await axiosInstance.get(`${API_URL}/api/account-list/`);
            return response.data;
        } catch (error) {
            console.error("Error fetching sales details:", error);
            return null;
        }
    };

    export const fetchCashierData = async (cashierId) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/api/cashier/${cashierId}/`);

            return response.data;
        } catch (error) {
            console.error("Error fetching sales details:", error);
            return null;
        }
    };

    export const fetchSalesDeatils = async (salesId) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/api/sales/${salesId}/`);

            return response.data;
        } catch (error) {
            console.error("Error fetching sales details:", error);
            return null;
        }
    };

    export const fetchCustomerDetails = async (customerId) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/api/customer/${customerId}/`);

            return response.data;
        } catch (error) {
            console.error("Error fetching sales details:", error);
            return null;
        }
    };

export const fetchBikeInAccountStageTwo = async (stage) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/bikes/stage/bikestage/${stage}/`);

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};


export const fetchBikeDetails = async (bikeId) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/bikes/${bikeId}/`);

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const ninetyPercentAction = async (customerId) => {
    try {
        const response = await axiosInstance.put(`${API_URL}/api/update-ninety-percent-paid/${customerId}/`);

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const customStyles = {
    dropdown: (provided) => ({
        ...provided,
        width: '100%', // Ensure the dropdown takes full width
        backgroundColor: 'white', // Set a background color
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 1000, // Ensure the menu appears above other elements
    })
};
export const fetchCashierPaymentBreakUp = async (customerId) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/cashiers/${customerId}/`);

        return response.data.results;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const approveCashiers = async (cashierIds) => {
    try {
        const response = await axiosInstance.post(`${API_URL}/api/approve-cashiers/`,{
            cashier_ids: cashierIds
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};
export const declineCashiers = async (cashierIds) => {
    try {
        const response = await axiosInstance.post(`${API_URL}/api/decline-cashiers/`,{
            cashier_ids: cashierIds
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching updating Discount:", error);
        return null;
    }
};
export const checkninetyPercentAction = async (customerIds) => {
    try {
        const response = await axiosInstance.put(`${API_URL}/api/update-ninety-percent-paid/`,{
            customer_ids: customerIds
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};


export const updateCustomers = async (customerIds) => {
    try {
        const url = `${API_URL}/api/sales-by-customer-ids/?customer_ids=${customerIds}`;
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const moveCustomerOrBikeToNextSatge = async (customerIds, bikeIds) => {
    try {
        const response = await axiosInstance.put(`${API_URL}/api/move-customers-to-stage/`,{
            customer_ids_stage2: customerIds,
            customer_ids_stage7: bikeIds
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};


export const discountApproval = async (salesIds) => {
    try {
        const response = await axiosInstance.put(`${API_URL}/api/update-sales-entries/`,{
            sales_ids: salesIds
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const checkFullPaymentReceived = async (customerIds) => {
    try {
        const response = await axiosInstance.put(`${API_URL}/api/check-received-amount/`,{
            customers: customerIds
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const discountDecline = async (salesIds) => {
    try {
        const response = await axiosInstance.put(`${API_URL}/api/decline-discount/`,{
            sales_ids: salesIds
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const generateReceipt = async (cashierID) => {
    try {
        // Note: Axios response type is set to 'blob' to handle the PDF binary data
        const response = await axiosInstance.get(`${API_URL}/api/generate-receipt/${cashierID}/`, {
            responseType: 'blob', // Important: Specifies the response data type
        });

        // Create a Blob from the PDF Stream
        const file = new Blob(
            [response.data], 
            { type: 'application/pdf' }
        );

        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        // Create a link and trigger the download
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `receipt_${cashierID}.pdf`); // or any other filename
        document.body.appendChild(link);
        link.click();

        // Clean up and revoke the URL object
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        // successMessage("Downloaded successfully")

    } catch (error) {
        console.error("Error fetching or downloading the receipt:", error);
    }
};

export const fetchRtoDetails = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/rto-details/`);
        const rtoDetails = response.data.map(rto => ({
            value: {
                code: rto.rto_code,
                isOutstation: rto.is_outstation,
                isOutstationMysore: rto.is_outstation_mysore
            },
            label: `${rto.rto_code} - ${rto.rto_name} - ${rto.rto_location}`
        }));
        return rtoDetails;
    } catch (error) {
        console.error("Failed to fetch RTO details:", error);
        throw error;  // Rethrowing the error to be handled by the caller
    }
};

export const fetchCustomersInSaleOne = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/customer/stage/1/`);
        let customers = response.data;

        // Sort customers by booking date for stage 2
        customers.sort((a, b) => new Date(a.date) - new Date(b.date));

        return customers;
    } catch (error) {
        console.log("Error fetching customers:", error);
    }
};

export const fetchPeopleInSalesTeam = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/sales_team/`);
        const salesTeamMembers = response.data.map(member => ({
            value: member.id,
            label: member.name,
            location: member.location
        }));
        return salesTeamMembers;
    } catch (error) {
        console.error("Error fetching sales team members:", error);
        throw error; // Throw error to be handled by the caller 
    }
};

export const fetchBikesInSales = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/bikes/stage/bikestage/2/`);
        return response.data
    } catch (error) {
        console.error("Error fetching bikes:", error);
        throw error; // Throw error to be handled by the caller
    }
};

export const generatePaymentBreakUpReceipt  = async (customerName, customerEmail, customerNumber, totalPayReceivable, paymentBreakdown, bike_model, bike_color, sales_person) => {
    try {
        const response = await axiosInstance.post(`${API_URL}/api/generate-payment-breakdown/`, {
            customer_name: customerName,
            customer_email: customerEmail,
            customer_number: customerNumber,
            total_pay_receivable: totalPayReceivable,
            bike_model: bike_model,
            bike_color: bike_color,
            payment_breakdown: paymentBreakdown,
            sales_person: sales_person,
        }, {
            responseType: 'blob' // This ensures that the response is a Blob
        });

        // Create a URL from the Blob and suggest a filename
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'payment-breakdown.pdf'); // or dynamically set the filename
        document.body.appendChild(fileLink);

        fileLink.click(); // Programmatically click the link to trigger the download

        fileLink.remove(); // Remove the link when done
        successMessage("File Downloaded Successfully")
    } catch (error) {
        console.error("Error while downloading payment breakdown", error);
        return null;
    }
};

export const generateAccessoryReceipt  = async (customerid, accessoryBreakdown, salesperson, customerEmail) => {
    try {
        const response = await axiosInstance.post(`${API_URL}/api/generate-accessory-breakdown/`, {
            customer_id: customerid,
            accessory_breakdown: accessoryBreakdown,
            sales_person: salesperson,
            customer_email : customerEmail,
        }, {
            responseType: 'blob' // This ensures that the response is a Blob
        });

        // Create a URL from the Blob and suggest a filename
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'accessory-breakdown.pdf'); // or dynamically set the filename
        document.body.appendChild(fileLink);

        fileLink.click(); // Programmatically click the link to trigger the download

        fileLink.remove(); // Remove the link when done
    } catch (error) {
        console.error("Error while downloading payment breakdown", error);
        return null;
    }
};

export const fetchCustomerIds = async (customerId) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/documents/customer/${customerId}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const fetchBikeDocuments = async (bikeId) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/documents/bike/${bikeId}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching bike documents:", error);
        return null;
    }
};


const CustomInputField = styled(TextField)(({ theme, label }) => ({
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: label === "Delivery Date" ? '10rem' : '250px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: label === "Delivery Date" ? '57rem' : '30rem',
    },
  }));
  
  export const CustomInput = React.forwardRef(({ value, onClick, label }, ref) => {
    return (
      <Box my={2} sx={{ width: '100%' }}>
        <CustomInputField
          label={label || "Date"}
          type="text"
          onClick={onClick}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={value}
          fullWidth
          placeholder="DD/MM/YYYY"
          ref={ref}
        />
      </Box>
    );
});

// util.js or wherever your utility functions are stored
export const calculateAgeInDays = (manufactureDate) => {
    const today = new Date();
    const birthDate = new Date(manufactureDate);
    const timeDifference = today - birthDate;
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
};

export const fetchCustomersforSalesTwo = async () => {

    try {
        const response = await axiosInstance.get(`${API_URL}/api/customer/stage/3/`);
        let customers = response.data;

        // Sort customers by booking date for stage 2
        customers.sort((a, b) => new Date(a.date) - new Date(b.date));
        return customers;

    } catch (error) {
        return [];
    }
};

export const fetchSalesDetailsByBikeId = async (bikeId) => {

    try {
        const response = await axiosInstance.get(`${API_URL}/api/sales/by_bike/${bikeId}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};
export const fetchAccessoriesList = async (bikeModel) => {

    try {
        const response = await axiosInstance.get(`${API_URL}/api/accessories/${bikeModel}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const fetchAccessoryNames = async (accessoryIds) => {
    try {
        const response = await axiosInstance.put(`${API_URL}/api/get_accessory_names/`,{
            accessory_ids: accessoryIds
        });

        return response.data;
    } catch (error) {
        console.error("Error Fetching Accessory Names:", error);
        return null;
    }
};

export const sendSms = async (number, message) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/send-sms/${number}/${message}/`);
        let customers = response.data;

        // Sort customers by booking date for stage 2
        customers.sort((a, b) => new Date(a.date) - new Date(b.date));

        return customers;
    } catch (error) {
        console.log("Error sending massage:", error);
    }
};

export const createBikeTime = async (bikeId, field_to_update) => {
    try {
        const currentDateAndTime = moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
        const response = await axiosInstance.post(`${API_URL}/api/manage-bike-time/`, {
            bike_id: bikeId,
            [field_to_update]: currentDateAndTime,
        });
        return response.data;
    } catch (error) {
        console.log("Error sending massage:", error);
    }
};

export const updateBikeTime = async (bikeId, field_to_update, currentDateAndTime = null) => {
    try {
        if (currentDateAndTime === null) {
            currentDateAndTime = moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
        }
        const response = await axiosInstance.put(`${API_URL}/api/manage-bike-time/`, {
            bike_id: bikeId,
            [field_to_update]: currentDateAndTime,
        });
        return response.data;
    } catch (error) {
        console.log("Error sending massage:", error);
    }
};

export const updateBikeTimeMultipule = async (bikeIds, payloadField) => {
    try {
        const currentDateAndTime = moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");

        const response = await axiosInstance.put(`${API_URL}/api/update-bikes-time/`,{
            bike_ids: bikeIds,
            [payloadField]: currentDateAndTime,
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const FetchBikeTime = async (bikeId) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/get-bike-time/${bikeId}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const createCustomerTime = async (customerId, field_to_update) => {
    try {
        const currentDateAndTime = moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
        const response = await axiosInstance.post(`${API_URL}/api/manage-customer-time/`, {
            customer_id: customerId,
            [field_to_update]: currentDateAndTime,
        });
        return response.data;
    } catch (error) {
        console.log("Error sending massage:", error);
    }
};

export const updateCustomerTime= async (customerId, field_to_update, currentDateAndTime = null) => {
    try {
        if (currentDateAndTime === null) {
            currentDateAndTime = moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
        }
        const response = await axiosInstance.put(`${API_URL}/api/manage-customer-time/`, {
            customer_id: customerId,
            [field_to_update]: currentDateAndTime,
        });
        return response.data;
    } catch (error) {
        console.log("Error sending massage:", error);
    }
};
export const updateCustomerTimeMultipule = async (CustomerIds, payloadField) => {
    try {
        const currentDateAndTime = moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");

        const response = await axiosInstance.put(`${API_URL}/api/update-customer-time/`,{
            customer_ids: CustomerIds,
            [payloadField]: currentDateAndTime,
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const FetchCustomerTime = async (customerId) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/get-customer-time/${customerId}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const branchOptions = [
    { label: "All Branch", value: "" },
    { label: "Residency Road", value: "Keerthi Residency Road" },
    { label: "JP Nagar", value: "Keerthi JP Nagar" },
    { label: "Mysore", value: "Keerthi Mysore" },
    { label: "HBR Layout", value: "Keerthi HBR layout" },
];

export const branchNameMapping = {
    "Keerthi Residency Road": "Residency Road",
    "Keerthi JP Nagar": "JP Nagar",
    "Keerthi Mysore": "Mysore",
    "Keerthi HBR layout": "HBR Layout",
};

export const stockYardOptions = [
    { label: "All Stockyard", value: "" },
    { label: "Bangalore", value: "Bangalore" },
    { label: "Mysore", value: "Mysore" },
];



export const bulkHSRPUpdate = async (bikeIds, hsrpReferenceNumber, hsrpOrderDate) => {
    try {
        const payload = {
            bike_ids: bikeIds,
            hsrp_reference_number: hsrpReferenceNumber,
            hsrp_order_date: hsrpOrderDate,
        };

        const response = await axiosInstance.post(`${API_URL}/api/hsrp/bulk-update/`, payload);

        return response.data;
    } catch (error) {
        console.error("Error updating HSRP details:", error);
        return null;
    }
};

export const downloadExcelFile = async (bikeIds) => {
    try {
        const response = await axiosInstance.post(
            `${API_URL}/api/hsrp/download-excel/`,
            { bike_ids: bikeIds },
            {
                responseType: 'blob', // Important for handling binary data
            }
        );

        // Create a URL for the file and trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'HSRP_Bikes.xlsx'); // Name of the downloaded file
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error("Error downloading Excel file:", error);
    }
};

export const fetchCustomersInSalesManager = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/customer/stage_sales_manager_approval/`);
        let customers = response.data;

        // Sort customers by booking date for stage 2
        customers.sort((a, b) => new Date(a.date) - new Date(b.date));

        return customers;
    } catch (error) {
        console.log("Error fetching customers:", error);
    }
};

export const IndianStates = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa',
    'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala',
    'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland',
    'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura',
    'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands',
    'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Delhi', 'Lakshadweep',
    'Puducherry'
];

export const FinancierBankOptions = [{ value: 'Bajaj', label: 'Bajaj' }];
export const SpecialNumberOptions = [{ value: 'N/A', label: 'No Special Number' }];


// Correnly not using this api anywhere 
export const filterBikeByStageAndDepartment = async (stage) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/bikes/separate/${stage}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const fetchDeliveryDetails = async (bikeId) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/delivery/update_by_bike/${bikeId}/`);
        return response.data;
    } catch (error) {
        return null;
    }
  };

//  we are not using this api 
export const filterBikeByStageAndStockyard = async (stage) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/bikes/separate/pdi/${stage}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching sales details:", error);
        return null;
    }
};

export const fetchDamagedBikeIdsforAll = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/pdi/damaged/`);
        return response.data.damagedBikeIds;
    } catch (error) {
        console.error("Error fetching damaged bike IDs:", error);
        return null;
    }
};

export const fetchPdiDetails = async (bikeId) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/pdi/update_by_bike/${bikeId}/`);
        return response.data;
    } catch (error) {
        return null;
    }
};

export const fetchInsuranceBikesByCategory = async (category, timeframe = 'today',branch,count=false) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/insurance-bikes-by-category/${category}/`, {
            params: { timeframe,branch,count }
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch bikes by category');
    }
};


export const fetchRTOBikesByCategory = async (category, timeframe = 'today', branch, count) => {
    try {
        if(category=="specialNumber"){
            return [];
        }
        const response = await axiosInstance.get(`${API_URL}/api/rto-bikes-by-category/${category}/`, {
            params: { timeframe, branch, count },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch bikes by category');
    }
};
export const fetchDataEntryCustomersByCategory = async (category,count=false) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/data-entry-customer-by-category/${category}/`,{
            params:{
                // branch,
                count
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch bikes by category');
    }
};
export const fetchBikeEntryCustomersByCategory = async (category,count=false) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/bike-entry-customer-by-category/${category}/`,{
            params:{
                // branch,
                count
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch bikes by category');
    }
};
export const fetchStockyardDeatilsByCategory = async (category,branch, count) => {
    try {
        console.log(branch)
        const response = await axiosInstance.get(`${API_URL}/api/bike-stockyard-by-category/${category}/`,{
            params:{
            branch,
            count
            }
            
            });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch bikes by category');
    }
};
export const fetchSalesPersonCustomersByCategory = async (timeframe, count = false, salesPerson = null, startDate = null, endDate = null) => {
  try {
    // Construct the base URL without a salesperson by default
    let url = `${API_URL}/api/sales_person_customers`;

    // If a salesperson is provided, append it to the URL
    if (salesPerson) {
      url = `${url}/${salesPerson}`; // Only append the salesperson name if it exists
    }

    // Prepare the params object
    const params = { count };

    // Only add startDate and endDate to params if they are provided
    params.start_date =  moment(startDate || new Date()).format("YYYY-MM-DD")
    if (endDate) params.end_date =moment(endDate).format("YYYY-MM-DD")

    // Make the GET request with query parameters
    const response = await axiosInstance.get(url, { params });

    // Return the response data directly
    return response.data;

  } catch (error) {
    console.error('Error fetching sales person customers:', error);
    throw new Error('Failed to fetch sales person customers');
  }
};
  
  
  

export const fetchPDIBikesByCategory = async (category,branch,count=false) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/pdi-bikes-by-category/${category}/`,{
            params:{
                branch,
                count
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching PDI1 bikes by category:', error);
        throw error;
    }
};

export const fetchPDI2BikesByCategory = async (category,branch,count) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/pdi2-bikes-by-category/${category}/`,{
            params:{
                branch,
                count
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching PDI2 bikes by category:', error);
        throw error;
    }
};

export const fetchPDI3BikesByCategory = async (category,branch,count) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/pdi3-bikes-by-category/${category}/`,{
            params:{
                branch,
                count
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching PDI3 bikes by category:', error);
        throw error;
    }
};  

export const fetchDelivery1BikesByCategory = async (category, timeframe = 'today',branch,count=false) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/delivery1-bikes-by-category/${category}/`, {
            params: {
                 timeframe,
                branch,
                count
                }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Delivery1 bikes by category:', error);
        throw error;
    }
};

export const fetchDelivery2BikesByCategory = async (category, timeframe = 'today',branch,count) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/delivery2-bikes-by-category/${category}/`, {
            params: {
                timeframe: timeframe,
                branch,
                count
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Delivery2 bikes by category:', error);
        throw error;
    }
};

export const fetchDelivery3BikesByCategory = async (category, timeframe = 'today',branch,count=false) => {
    try {
        if(category=="balanceLessThanOrEqual0"){
            return [];
        }
        const response = await axiosInstance.get(`${API_URL}/api/delivery3-bikes-by-category/${category}/`, {
            params: {
                timeframe: timeframe,
                branch,
                count
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Delivery3 bikes by category:', error);
        throw error;
    }
};
export const fetchDelivery4BikesByCategory = async (category, timeframe = 'today',branch,count=false) => {
    try {
        if(category=="balanceLessThanOrEqual0"){
            return [];
        }
        const response = await axiosInstance.get(`${API_URL}/api/delivery4-bikes-by-category/${category}/`, {
            params: {
                timeframe: timeframe,
                branch,
                count
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Delivery3 bikes by category:', error);
        throw error;
    }
};

export const fetchCashierDataByCategory = async (category, timePeriod = 'today',branch,count=false) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/cashier-data-by-category/${category}/`, {
            params: {
                timePeriod,
                branch,
                count
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching cashier data by category:', error);
        throw error;
    }
};

export const fetchDispatchList = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/api/dispatch-list/`);
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch dispatch list');
    }
};

export const handleDispatchUpdate = async (bikeIds, dispatchDate, dispatchTime) => {
    try {

        const response = await axiosInstance.put('/api/pdi/update-stockyard-dispatch/', {
            bike_ids: bikeIds,
            stockyard_dispatch_date: dispatchDate,
            stockyard_dispatch_time: dispatchTime,
        });

        return response.data;
    } catch (error) {
        console.error('Error updating dispatch date and time:', error);
        throw error; // Re-throw the error so it can be handled by the calling function
    }
};

export const formatDate = (date) => date ? moment(date, "YYYY/MM/DD").format("DD/MM/YYYY") : '';

// export const PaymentBreakUp = async (customerId) => {
//     setIsLoading(true);
//     try {
//         const salesDetails = await fetchSalesDetailsByCustomerID(customerId)
         
//         let newBalance = salesDetails.balance || 0;
//         setIsLoading(false);
//         const customerPaymentBreakdownCopy = { ...customerPaymentBreakdown };

//         const newCashierReceivedAmount = await fetchReceivedAmountCashier(customerId)
//         const newPaymentsReceivedApproved = newCashierReceivedAmount.total_amount_paid_approve || 0;
//         const newPaymentsReceivedTotal = newCashierReceivedAmount.total_amount_paid || 0;


//         Object.keys(salesDetails.payment_breakdown).forEach(key => {
//             // Check if the key exists in customerPaymentBreakdown and update its value
//             if (customerPaymentBreakdownCopy.hasOwnProperty(key)) {
//                 customerPaymentBreakdownCopy[key] = salesDetails.payment_breakdown[key];
//             }
//         });



//         setCustomerPaymentBreakdown(customerPaymentBreakdownCopy);

//         setTotalPay(salesDetails.totalpay_receivable)
//         setBalance(newBalance);
//         setPaymentsReceivedApproved(newPaymentsReceivedApproved.toFixed(2));
//         setPaymentReceivedTotal(newPaymentsReceivedTotal.toFixed(2))
//         setIsOpen(true);


//     } catch (error) {
//         console.log("No Sales Record For this Customer");
//     }
//     setIsLoading(false);

// }
export const createOrUpdateRTO = async (bikeId, rtoData) => {
    try {
        const response = await axiosInstance.post(`${API_URL}/api/rto/create-or-update/`, {
            bike: bikeId,
            ...rtoData
        });
        console.log('RTO record created/updated successfully:', response.data);
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else if (error.request) {
            console.error('Error request:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
    }
};

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export async function fetchCsrfToken() {
    const response = await fetch(`${API_URL}/api/get-csrf-token/`, {
        credentials: 'include'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch CSRF token');
    }
    return response;
}